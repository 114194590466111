<template>
  <div>
    <custom-toolbar
      :total="$store.getters['campaign/total']"
      :title="$t('Campagnes')"
      :add="'Nouvelle campagne'"
      @refresh="getList"
      @add="openForm"
    />
    <v-card class="mb-6 mt-6">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="$store.state.campaign.field.label"
              label="Rechercher par libellé"
              outlined
              dense
              placeholder="Rechercher par libellé"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['campaign/campaigns']"
        :items-per-page="$store.getters['campaign/per_page']"
        :page.sync="$store.getters['campaign/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.color`]="{item}">
          <v-chip
            :color="item.color"
            small
          >
            {{ item.color }}
          </v-chip>
        </template>

        <template #[`item.image`]="{item}">
          <v-img
            width="50"
            height="auto"
            :src="`${item.image['fr']}`"
          ></v-img>
        </template>
        <template #[`item.title`]="{item}">
          {{ item.title['fr'] }}
        </template>
        <template #[`item.target`]="{item}">
          {{ getTargetText(item.target) }}
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            v-if="!item.status"
            color="warning"
            small
          >
            <span class="font-weight-bold">{{ $t("Inactif")}}</span>
          </v-chip>
          <v-chip
            v-else
            color="success"
            small
          >
            <span class="font-weight-bold">{{ $t('Actif') }}</span>
          </v-chip>
        </template>
        <template #[`item.sent`]="{item}">
          <v-chip
            v-if="item.sent"
            color="success"
            small
          >
            <span class="font-weight-bold">{{ $t("Envoyé")}}</span>
          </v-chip>
          <v-chip
            v-else
            color="error"
            small
            text
          >
            <span class="font-weight-bold">{{ $t("non envoyé")}}</span>
          </v-chip>

        </template>
        <template #[`item.opens_count`]="{item}">

          <v-chip
            :color="(item.nb_users > 0?Math.floor(((item.opens_count || 0)/(item.nb_users || 0)) * 100): 0) >= 50? 'success': 'warning'"
            small
            outlined
          >
            <span class="font-weight-bold">{{item.opens_count}} | {{ item.nb_users > 0?Math.floor(((item.opens_count || 0)/(item.nb_users || 0)) * 100): 0}}%</span>
          </v-chip>

        </template>
        <template #[`item.deleted_at`]="{item}">
          <v-chip
            v-show="item.deleted_at !== null"
            color="error"
            small
          >
            <span class="font-weight-bold">{{ item.deleted_at ? formattingDate(`${item.deleted_at}`) : null }}</span>
          </v-chip>
        </template>
        <template #[`item.send_date`]="{item}">
            <v-chip
              :color="(new Date(item.send_date)) > new Date() ? 'primary' : 'error'"
              small
            >
              <span class="font-weight-bold"> {{ formattingDate(`${item.send_date}`) }}</span>
            </v-chip>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :lock="false"
            :status="false"
            :view="false"
            @edit="openForm(item)"
            @remove="openItem(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.recovery.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getListPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getList"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <campaign-form
      :dialog="dialog"
      :object="item"
      @clickout="closeDialog"
    />
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="deleteItem"
    />
  </div>
</template>

<script>
import {
  getCurrentInstance, onMounted, ref, watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { formatDate, formatDateWithoutHour } from '@core/date/dateFunctions'
import {
  mdiAccountLock,
  mdiDeleteOutline, mdiDeleteRestore,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus, mdiRefresh,
  mdiSquareEditOutline,
} from '@mdi/js'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import CampaignForm from '@core/components/campaign/CampaignForm'
import {intToCurrency, notify} from '@core/dynamicComponents/dynamicComponents'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import store from '@/store'

export default {
  name: 'Campaign',
  components: {
    ButtonsGroup,
    CustomToolbar,
    CampaignForm,
    LoaderDialog,
    ConfirmDialog,
  },
  setup() {
    const instance = getCurrentInstance()
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const item = ref({
      title: {
        fr: null, en:null,
      },
      body: {
        fr: null, en:null,
      },
      target: 'all',
      user_ids: [],
      send_date: null,
      status: 0,
      image: {
        fr: null,
        en: null,
      }
    })
    const date = ref(null)
    const message = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const menu = ref(false)
    const messageDialog = ref('')
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const dialogDelete = ref(false)
    const isAlertVisible = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const targets = ref([
      { text: 'Tous', value: 'all' },
      { text: 'Chauffeurs', value: 'drivers' },
      { text: 'Passagers', value: 'passengers' },
      { text: 'Spécifique', value: 'specific' },
    ])
    const { router } = useRouter()

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const save = d => {
      menuref.value.save(d)
    }

    const getList = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('campaign/getCampaignsList', { page, field: store.state.campaign.field }).then(() => {
        currentPage.value = store.getters['campaign/current_page']
        lastPage.value = store.getters['campaign/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const refreshList = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('campaign/refreshCampaignsList', { page, field: store.state.campaign.field }).then(() => {
        currentPage.value = store.getters['campaign/current_page']
        lastPage.value = store.getters['campaign/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const filterItems = () => {
      setTimeout(() => {
        getList()
      }, 1500)
    }

    const getListPerPage = per_page => {
      isDialogVisible.value = true
      store.state.campaign.field.paginate = per_page
      store.dispatch('campaign/getCampaignsList', { field: store.state.campaign.field }).then(() => {
        currentPage.value = store.getters['campaign/current_page']
        lastPage.value = store.getters['campaign/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const deleteItem = () => {
      isDialogVisible.value = true
      if (item.value.deleted_at === null) {
        store.dispatch('campaign/deleteCampaign', item.value).then(response => {
          isDialogVisible.value = false
          isAlertVisible.value = false
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          isAlertVisible.value = true
          dialogDelete.value = false
          notify(false, true, err.response.data.message)
        })
      } else {
        store.dispatch('campaign/restoreCampaign', item.value).then(response => {
          isDialogVisible.value = false
          isAlertVisible.value = false
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          isAlertVisible.value = true
          dialogDelete.value = false
          notify(false, true, err.response.data.message)
        })
      }
    }

    const openForm = val => {
      if (val) {
        val.send_date = new Date(val.send_date || null).toISOString().substr(0, 10)
        item.value = val
      } else {
        item.value = {
          id:null,
          title: {
            fr: null, en:null,
          },
          body: {
            fr: null, en:null,
          },
          target: 'all',
          user_ids: [],
          send_date: new Date().toISOString().substr(0, 10),
          send_time: '00:00',
          status: 0,
          image: {
            fr: null,
            en: null,
          }
        }
      }
      dialog.value = true
    }

    const formattingDate = date => formatDate(date)

    const formattingDateExHour = date => formatDateWithoutHour(date)

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const deleteItemConfirm = () => {
      this.closeDelete()
    }

    const openItem = object => {
      messageDialog.value = object.deleted_at === null ? `Voulez-vous supprimer le code ${object.label}?` : `Voulez-vous restaurer le code ${object.label}?`
      item.value = object
      dialogDelete.value = true
    }

    onMounted(() => {
      store.state.campaign.field.user_id = null
      getList(store.getters['campaign/current_page'])
    })

    const openDetails = item => {
      router.push({ name: 'showcampaign', params: { id: item.id } })
    }

    watch(() => store.state.user.lang, lang => {
      refreshList(store.getters['campaign/current_page'])
    })

    const getTargetText = target => {
      return (targets.value.find((e) => e.value == target ) || {text: '-'}).text
    }
    return {
      getTargetText,
      formatAmount,
      openDetails,
      deleteItem,
      dialog,
      item,
      messageDialog,
      formattingDateExHour,
      loading,
      closeDialog,
      openForm,
      filterItems,
      openItem,
      getListPerPage,
      deleteItemConfirm,
      closeDelete,
      save,
      getList,
      formattingDate,
      currentPage,
      message,
      lastPage,
      isAlertVisible,
      isDialogVisible,
      dialogDelete,
      error,
      date,
      menu,
      picker,
      menuref,
      headers: [
        { text: 'LABEL', value: `title` },
        { text: 'IMAGE', value: 'image' },
        { text: 'CIBLE', value: 'target' },
        { text: 'UTILISATEURS', value: 'nb_users' },
        { text: 'OUVERTS', value: 'opens_count' },
        { text: 'STATUT', value: 'status' },
        { text: 'ENVOI', value: 'sent' },

        { text: 'DATE D\'ENVOI', value: 'send_date' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiDeleteRestore,
        mdiPlus,
      },
    }
  },
}
</script>

<style scoped>

</style>
