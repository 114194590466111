var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-toolbar',{attrs:{"total":_vm.$store.getters['campaign/total'],"title":_vm.$t('Campagnes'),"add":'Nouvelle campagne'},on:{"refresh":_vm.getList,"add":_vm.openForm}}),_c('v-card',{staticClass:"mb-6 mt-6"},[_c('v-alert',{attrs:{"color":_vm.error ? 'error' : 'success',"dismissible":"","text":"","transition":"slide-y-transition"},model:{value:(_vm.isAlertVisible),callback:function ($$v) {_vm.isAlertVisible=$$v},expression:"isAlertVisible"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('div',{staticClass:"m-2"},[_c('v-row',{staticClass:"ml-5 mr-5"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Rechercher par libellé","outlined":"","dense":"","placeholder":"Rechercher par libellé","hide-details":"auto","clearable":""},on:{"input":_vm.filterItems},model:{value:(_vm.$store.state.campaign.field.label),callback:function ($$v) {_vm.$set(_vm.$store.state.campaign.field, "label", $$v)},expression:"$store.state.campaign.field.label"}})],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.isDialogVisible,"loading-text":"Chargement...","headers":_vm.headers,"items":_vm.$store.getters['campaign/campaigns'],"items-per-page":_vm.$store.getters['campaign/per_page'],"page":_vm.$store.getters['campaign/current_page'],"dense":"","fixed-header":"","height":"500","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.$store.getters, 'campaign/current_page', $event)},"page-count":function($event){_vm.currentPage = $event}},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color,"small":""}},[_vm._v(" "+_vm._s(item.color)+" ")])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"width":"50","height":"auto","src":("" + (item.image['fr']))}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title['fr'])+" ")]}},{key:"item.target",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTargetText(item.target))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.created_at))))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.updated_at))))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.status)?_c('v-chip',{attrs:{"color":"warning","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Inactif")))])]):_c('v-chip',{attrs:{"color":"success","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('Actif')))])])]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [(item.sent)?_c('v-chip',{attrs:{"color":"success","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Envoyé")))])]):_c('v-chip',{attrs:{"color":"error","small":"","text":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("non envoyé")))])])]}},{key:"item.opens_count",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.nb_users > 0?Math.floor(((item.opens_count || 0)/(item.nb_users || 0)) * 100): 0) >= 50? 'success': 'warning',"small":"","outlined":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.opens_count)+" | "+_vm._s(item.nb_users > 0?Math.floor(((item.opens_count || 0)/(item.nb_users || 0)) * 100): 0)+"%")])])]}},{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.deleted_at !== null),expression:"item.deleted_at !== null"}],attrs:{"color":"error","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.deleted_at ? _vm.formattingDate(("" + (item.deleted_at))) : null))])])]}},{key:"item.send_date",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(new Date(item.send_date)) > new Date() ? 'primary' : 'error',"small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.send_date)))))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('buttons-group',{attrs:{"item":item,"lock":false,"status":false,"view":false},on:{"edit":function($event){return _vm.openForm(item)},"remove":function($event){return _vm.openItem(item)}}})]}}],null,true)}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('v-text-field',{attrs:{"value":_vm.$store.state.recovery.field.paginate,"label":"Lignes par page:","type":"number","min":"-1","max":"15","hide-details":""},on:{"input":_vm.getListPerPage}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.lastPage},on:{"input":_vm.getList},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('campaign-form',{attrs:{"dialog":_vm.dialog,"object":_vm.item},on:{"clickout":_vm.closeDialog}}),_c('confirm-dialog',{attrs:{"message":_vm.messageDialog,"dialog-delete":_vm.dialogDelete},on:{"clickout":function($event){_vm.dialogDelete = false},"submitted":_vm.deleteItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }